<script>
  // import router from '@/router'
  // import store from '@/store'

  // eslint-disable-next-line object-curly-newline
  import { mdiAccountOutline, mdiLinkVariant, mdiLockOutline } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";

  import { store } from "@/store";

  import { useUser } from "../useUser";
  import UserBioPanel from "./user-bio-panel/UserBioPanel";
  import UserTabConnections from "./user-tab-connections/UserTabConnections";
  import UserTabNotifications from "./user-tab-notifications/UserTabNotifications";
  import UserTabOverview from "./user-tab-overview/UserTabOverview";
  import UserTabSecurity from "./user-tab-security/UserTabSecurity";

  export default defineComponent({
    components: {
      UserBioPanel,
      UserTabOverview,
      UserTabSecurity,
      UserTabNotifications,
      UserTabConnections
    },
    setup() {
      const { user, isPending, hasLoaded } = useUser();
      const userTab = ref(null);
      const isPlanUpgradeDialogVisible = ref(false);

      const authUserId = ref(store.state.auth.user.id);

      const tabs = [
        { icon: mdiAccountOutline, title: "Overview" },
        { icon: mdiLockOutline, title: "Security" },
        //{ icon: mdiBellOutline, title: "Notifications" },
        { icon: mdiLinkVariant, title: "Connections" }
      ];

      return {
        tabs,
        userTab,
        isPlanUpgradeDialogVisible,
        user,
        authUserId,
        isPending,
        hasLoaded
      };
    }
  });
</script>

<template>
  <div id="user-view">
    <v-row v-if="!isPending">
      <v-col cols="12" md="5" lg="4">
        <user-bio-panel
          :user-data="user"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
        ></user-bio-panel>
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="userTab" show-arrows class="user-tabs">
          <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            v-if="
              (user.id === authUserId && tab.title === 'Security') ||
              tab.title !== 'Security'
            "
          >
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-if="hasLoaded"
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-tab-overview
              :contact-id="user.contactId"
              :user-id="user.id"
              :auth-user-id="authUserId"
            ></user-tab-overview>
          </v-tab-item>

          <v-tab-item v-if="user.id === authUserId">
            <user-tab-security></user-tab-security>
          </v-tab-item>
          <!--<v-tab-item>
            <user-tab-notifications></user-tab-notifications>
          </v-tab-item>-->
          <v-tab-item>
            <user-tab-connections></user-tab-connections>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
  @import "@/preset/preset/apps/user.scss";
</style>
