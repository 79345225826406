<script>
  import { defineComponent } from "@vue/composition-api";

  import { useUserProjectList } from "@/views/apps/project/project-list/useProjectList.js";
  //import store from "@/store";

  export default defineComponent({
    props: {
      contactId: {
        required: true
      },
      userId: {
        required: true
      },
      authUserId: {
        required: true
      }
    },
    setup(props) {
      const tableColumnHeaders = [
        {
          text: "PROJECT",
          value: "project",
          sortable: false
        },
        { text: "STATUS", value: "status", sortable: false }
        //{ text: "EXPENDED", value: "progress", sortable: false },
        //{ text: "DAYS", value: "hours", sortable: false }
      ];
      // const authUserContactId = computed(() => {
      //   return store.state.auth.user.contactId;
      // });
      const { userProjects, userProjectsLoaded, userProjectsLatestQuery } =
        useUserProjectList(props.contactId);
      return {
        tableColumnHeaders,
        //authUserContactId,
        userProjects,
        userProjectsLoaded,
        userProjectsLatestQuery
      };
    }
  });
</script>

<template>
  <div class="user-tab-overview">
    <!-- user project list -->
    <v-card v-if="userProjectsLoaded" class="mb-7">
      <v-card-title> Project List </v-card-title>
      <v-data-table
        v-if="userProjectsLatestQuery"
        :headers="tableColumnHeaders"
        :items="userProjectsLatestQuery.response.data"
        :server-items-length="userProjectsLatestQuery.response.total"
        hide-default-footer
      >
        <!-- project -->
        <template #[`item.project`]="{ item }">
          <div class="d-flex align-center">
            <!--<v-avatar size="33" class="me-3">
              <v-img
                :src="
                  require(`@/assets/images/icons/project-icons/${item.logo}`)
                "
              ></v-img>
            </v-avatar>-->
            <div class="text-no-wrap">
              <p class="font-weight-medium text--primary mb-n1">
                {{ item.proceed_name }}
              </p>
              <span class="text-xs text--disabled">{{ item.status }}</span>
            </div>
          </div>
        </template>

        <!-- progress -->
        <!--<template #[`item.progress`]="{ item }">
          <span>{{ item.progress }}%</span>
          <v-progress-linear
            height="6"
            rounded
            class="project-progress mt-1"
            :color="resolveUserProgressVariant(item.progress)"
            :value="item.progress"
          ></v-progress-linear>
        </template>-->
      </v-data-table>
    </v-card>
    <v-card v-else>
      <v-card-title> Project List </v-card-title>
      <v-card-text>
        <p class="subtitle-2" v-if="userId == authUserId">
          You have no projects yet
        </p>
        <p class="subtitle-2" v-if="userId !== authUserId">
          This user does not have any projects.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>
