<script>
  import { defineComponent, ref, watch } from "@vue/composition-api";

  import { useOrganizationList } from "@/composables/useOrganizations";
  export default defineComponent({
    props: {
      isBioDialogOpen: {
        type: Boolean,
        required: true
      },
      userData: {
        type: Object,
        required: true
      }
    },
    setup(props, { emit }) {
      const { organizations, isPending } = useOrganizationList();
      const isBillingAddress = ref(true);

      const userDataLocal = ref({});
      userDataLocal.value = JSON.parse(JSON.stringify(props.userData));

      const statusOptions = [
        { title: "Pending", value: "pending" },
        { title: "Active", value: "active" },
        { title: "Inactive", value: "inactive" }
      ];
      const languageOptions = [
        "English",
        "Spanish",
        "Portuguese",
        "Russian",
        "French",
        "German"
      ];

      // on form submit
      const onSubmit = () => {
        emit("update:is-bio-dialog-open", false);
      };

      watch(
        () => props.isBioDialogOpen,
        () => {
          userDataLocal.value = JSON.parse(JSON.stringify(props.userData));
        }
      );

      return {
        organizations,
        isPending,
        isBillingAddress,
        statusOptions,
        languageOptions,
        userDataLocal,
        onSubmit
      };
    }
  });
</script>

<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="650px" persistent>
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit User Information
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Updating your details will force you to login again.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.firstName"
                outlined
                dense
                label="First Name"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.lastName"
                outlined
                dense
                label="Last Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.email"
                outlined
                dense
                label="Email Address"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="userDataLocal.orgId"
                :items="organizations"
                item-text="short_name"
                item-value="fulcrum_id"
                outlined
                dense
                label="Organization"
              ></v-select>
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                submit
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
