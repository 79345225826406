<script>
  import { mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";
  // Using VeeValidate for validations
  import { ValidationObserver, ValidationProvider } from "vee-validate";

  import { useChangeUserPassword } from "@/views/apps/user/useUser";

  export default defineComponent({
    components: {
      ValidationProvider,
      ValidationObserver
    },
    setup() {
      const form = ref(null);

      const {
        passwordChangeForm,
        isOldPasswordVisible,
        isNewPasswordVisible,
        isConfirmPasswordVisible,
        loading,
        showPwnWarning,
        onSubmit,
        resetForm
      } = useChangeUserPassword();

      const handleFormSubmit = async (passwordChangeForm) => {
        onSubmit(passwordChangeForm);
        form.value.reset();
      };

      const reset = async () => {
        try {
          resetForm();
        } catch (error) {
          console.error("Unable to clear form");
        } finally {
          form.value.reset();
        }
      };

      return {
        form,
        passwordChangeForm,
        isOldPasswordVisible,
        isNewPasswordVisible,
        isConfirmPasswordVisible,
        loading,
        showPwnWarning,
        handleFormSubmit,
        //onSubmit,
        reset,
        resetForm,
        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline
        }
      };
    }
  });
</script>

<template>
  <v-card class="mb-7">
    <v-card-title> Change Password </v-card-title>
    <v-card-text>
      <v-alert color="warning" text>
        <p class="font-weight-semibold mb-1">
          Ensure that these requirements are met
        </p>
        <p class="text-sm mb-0">
          Minimum 8 characters long, uppercase &amp; symbol
        </p>
      </v-alert>

      <!-- pwnd password alert -->
      <v-alert v-if="showPwnWarning" color="error" text>
        <p class="font-weight-semibold mb-1">Password Not Allowed</p>
        <p class="text-sm mb-3">
          The password you are trying to use has been blacklisted. Please submit
          a different one.
          <router-link to="/pages/faq" class="text-decoration-none mt-3">
            Learn more.
          </router-link>
        </p>
      </v-alert>
      <!-- form -->
      <validation-observer ref="form" v-slot="{ invalid, reset }">
        <form
          @submit.prevent="handleFormSubmit(passwordChangeForm)"
          @reset.prevent="reset"
        >
          <v-row>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Current Password"
                vid="oldPassword"
                rules="required"
              >
                <v-text-field
                  v-model="passwordChangeForm.oldPassword"
                  :error-messages="errors"
                  outlined
                  dense
                  clearable
                  label="Current Password"
                  :type="isOldPasswordVisible ? 'text' : 'password'"
                  :append-icon="
                    isOldPasswordVisible
                      ? icons.mdiEyeOutline
                      : icons.mdiEyeOffOutline
                  "
                  @click:append="isOldPasswordVisible = !isOldPasswordVisible"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="New Password"
                vid="newPassword"
                rules="required|regexStrongPassword"
              >
                <v-text-field
                  v-model="passwordChangeForm.newPassword"
                  :error-messages="errors"
                  outlined
                  dense
                  clearable
                  label="New Password"
                  :type="isNewPasswordVisible ? 'text' : 'password'"
                  :append-icon="
                    isNewPasswordVisible
                      ? icons.mdiEyeOutline
                      : icons.mdiEyeOffOutline
                  "
                  @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Confirm Password"
                vid="confirmPassword"
                rules="required|confirmPassword:@newPassword"
              >
                <v-text-field
                  v-model="passwordChangeForm.confirmPassword"
                  :error-messages="errors"
                  outlined
                  dense
                  clearable
                  label="Confirm New Password"
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  :append-icon="
                    isConfirmPasswordVisible
                      ? icons.mdiEyeOutline
                      : icons.mdiEyeOffOutline
                  "
                  @click:append="
                    isConfirmPasswordVisible = !isConfirmPasswordVisible
                  "
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-btn
                color="primary"
                outlined
                :disabled="loading"
                @click="reset()"
              >
                Clear Form
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn
                type="submit"
                color="primary"
                :loading="loading"
                :disabled="invalid || loading"
              >
                Change Password
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>
