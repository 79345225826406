<script>
  import { defineComponent } from "@vue/composition-api";

  import { store } from "@/store";

  export default defineComponent({
    setup() {
      const resolveFulcrumConnection = () => {
        // determined by the presence/absence of a `contactId`
        if (store.state.auth.user.contactId === null) {
          return false;
        } else {
          return true;
        }
      };

      return {
        resolveFulcrumConnection
      };
    }
  });
</script>

<template>
  <v-card class="mb-7">
    <v-card-title> Account Connections </v-card-title>
    <v-card-subtitle> Display your connected accounts </v-card-subtitle>

    <v-card-text class="pb-2">
      <v-list dense class="py-0">
        <v-list-item class="px-0">
          <v-img
            max-width="35"
            contain
            class="me-3"
            :src="require(`@/assets/images/logos/fulcrum.png`)"
          ></v-img>

          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <v-list-item-title class="text-sm"> Fulcrum </v-list-item-title>
              <v-list-item-subtitle class="mb-0">
                Field inspection tool (access is controlled by your
                Administrator)
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <v-switch v-model="resolveFulcrumConnection" disabled></v-switch>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
