<script>
  import {
    mdiBriefcaseVariantOutline,
    mdiCheck,
    mdiCheckboxBlankCircle
  } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";

  import { avatarText, formatDate, kFormatter } from "@/helpers/filter";
  import { useUsersList } from "@/views/apps/user/useUser";

  import UserBioEdit from "./UserBioEdit.vue";

  export default defineComponent({
    components: {
      UserBioEdit
    },
    props: {
      userData: {
        type: Object,
        required: true
      },
      isPlanUpgradeDialogVisible: {
        type: Boolean,
        required: true
      }
    },
    setup() {
      const {
        resolveUserStatusVariant,
        resolveUserStatusAlias,
        resolveUserRoleVariant
      } = useUsersList();

      const isBioDialogOpen = ref(false);

      return {
        resolveUserStatusVariant,
        resolveUserStatusAlias,
        resolveUserRoleVariant,
        avatarText,
        kFormatter,
        formatDate,

        isBioDialogOpen,
        icons: {
          mdiCheck,
          mdiBriefcaseVariantOutline,
          mdiCheckboxBlankCircle
        }
      };
    }
  });
</script>

<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="userData.avatar ? '' : 'primary'"
            :class="userData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img v-if="userData.avatar" :src="userData.avatar"></v-img>
            <span v-else class="font-weight-semibold text-5xl">{{
              avatarText(userData.fullName)
            }}</span>
          </v-avatar>

          <span class="mb-2">{{ userData.fullName }}</span>

          <v-chip
            label
            small
            :color="resolveUserRoleVariant(userData.role)"
            :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(
              userData.role
            )}--text text-capitalize`"
          >
            {{ userData.role }}
          </v-chip>
        </v-card-title>

        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon color="primary" size="22">
                {{ icons.mdiBriefcaseVariantOutline }}
              </v-icon>
            </v-avatar>

            <div>
              <!-- This number needs to be the total number of projects
                   not marked `Closed` that the user was involved with -->
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(userData.totalProjects) }}
              </h3>
              <span>Total Projects</span>
            </div>
          </div>

          <div class="d-flex align-center mb-4 me-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon color="primary" size="22">
                {{ icons.mdiCheck }}
              </v-icon>
            </v-avatar>

            <div>
              <!-- This number needs to be the total number of projects
                   marked `Closed` that the user was involved with -->
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(userData.completedProjects) }}
              </h3>
              <span>Projects Done</span>
            </div>
          </div>
        </v-card-text>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2"
                >Email Address:</span
              >
              <span class="text--secondary">{{ userData.email }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Status:</span>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="resolveUserStatusVariant(userData.active)"
                  :class="`v-chip-light-bg ${resolveUserStatusVariant(
                    userData.active
                  )}--text font-weight-medium text-capitalize`"
                >
                  {{ resolveUserStatusAlias(userData.active) }}
                </v-chip>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Role:</span>
              <span class="text--secondary text-capitalize">{{
                userData.role
              }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Organization:</span>
              <span class="text--secondary">{{ userData.organization }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Date Joined:</span>
              <span class="text--secondary">{{
                formatDate(userData.createdAt)
              }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Last Login:</span>
              <span class="text--secondary">{{
                userData.lastLoginAttempt
                  ? formatDate(userData.lastLoginAttempt)
                  : "Never"
              }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <!--<v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Edit
          </v-btn>
          <v-btn color="error" outlined> Suspended </v-btn>
        </v-card-actions>-->
      </v-card>

      <!-- edit profile dialog data -->
      <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
      ></user-bio-edit>
    </v-col>
  </v-row>
</template>
