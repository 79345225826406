var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-7"},[_c('v-card-title',[_vm._v(" Change Password ")]),_c('v-card-text',[_c('v-alert',{attrs:{"color":"warning","text":""}},[_c('p',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" Ensure that these requirements are met ")]),_c('p',{staticClass:"text-sm mb-0"},[_vm._v(" Minimum 8 characters long, uppercase & symbol ")])]),(_vm.showPwnWarning)?_c('v-alert',{attrs:{"color":"error","text":""}},[_c('p',{staticClass:"font-weight-semibold mb-1"},[_vm._v("Password Not Allowed")]),_c('p',{staticClass:"text-sm mb-3"},[_vm._v(" The password you are trying to use has been blacklisted. Please submit a different one. "),_c('router-link',{staticClass:"text-decoration-none mt-3",attrs:{"to":"/pages/faq"}},[_vm._v(" Learn more. ")])],1)]):_vm._e(),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit(_vm.passwordChangeForm)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"mode":"lazy","name":"Current Password","vid":"oldPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","dense":"","clearable":"","label":"Current Password","type":_vm.isOldPasswordVisible ? 'text' : 'password',"append-icon":_vm.isOldPasswordVisible
                    ? _vm.icons.mdiEyeOutline
                    : _vm.icons.mdiEyeOffOutline},on:{"click:append":function($event){_vm.isOldPasswordVisible = !_vm.isOldPasswordVisible}},model:{value:(_vm.passwordChangeForm.oldPassword),callback:function ($$v) {_vm.$set(_vm.passwordChangeForm, "oldPassword", $$v)},expression:"passwordChangeForm.oldPassword"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"mode":"lazy","name":"New Password","vid":"newPassword","rules":"required|regexStrongPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","dense":"","clearable":"","label":"New Password","type":_vm.isNewPasswordVisible ? 'text' : 'password',"append-icon":_vm.isNewPasswordVisible
                    ? _vm.icons.mdiEyeOutline
                    : _vm.icons.mdiEyeOffOutline},on:{"click:append":function($event){_vm.isNewPasswordVisible = !_vm.isNewPasswordVisible}},model:{value:(_vm.passwordChangeForm.newPassword),callback:function ($$v) {_vm.$set(_vm.passwordChangeForm, "newPassword", $$v)},expression:"passwordChangeForm.newPassword"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","vid":"confirmPassword","rules":"required|confirmPassword:@newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","dense":"","clearable":"","label":"Confirm New Password","type":_vm.isConfirmPasswordVisible ? 'text' : 'password',"append-icon":_vm.isConfirmPasswordVisible
                    ? _vm.icons.mdiEyeOutline
                    : _vm.icons.mdiEyeOffOutline},on:{"click:append":function($event){_vm.isConfirmPasswordVisible = !_vm.isConfirmPasswordVisible}},model:{value:(_vm.passwordChangeForm.confirmPassword),callback:function ($$v) {_vm.$set(_vm.passwordChangeForm, "confirmPassword", $$v)},expression:"passwordChangeForm.confirmPassword"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":_vm.loading},on:{"click":function($event){return reset()}}},[_vm._v(" Clear Form ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":invalid || _vm.loading}},[_vm._v(" Change Password ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }