<script>
  import { defineComponent } from "@vue/composition-api";

  export default defineComponent({
    setup() {}
  });
</script>

<template>
  <h3>Testing User Tab Notifications</h3>
</template>
